#todo {
  position:relative;
  transition: all .2s ease-in-out;
}

#todo:hover {
  transform: scale(1.1);
}

#todo a:after {
  color: white;
  content: 'A portfolio to hold all the little apps that I develop in my free time.';
  padding: 5px;
  font-size: .7em;
  line-height: 1.3em;
  background-color: rgba(00, 00, 00, .6);
  display: none;
  position: absolute;
  top: 3em;
  width: 98%;
  left: 0;
}

#todo:hover a:after {
  display:block;
}

#photography {
  position:relative;
  transition: all .2s ease-in-out;
}

#photography:hover {
  transform: scale(1.1);
}

#photography a:after {
  color: white;
  content: 'A photography portfolio. Technologies Used: CMS, Wordpress, Photoshop, CSS and HTML5';
  padding: 5px;
  font-size: .7em;
  line-height: 1.3em;
  background-color: rgba(00, 00, 00, .6);
  display: none;
  position: absolute;
  top: 3em;
  width: 98%;
  left: 0;
}

#photography:hover a:after {
  display:block;
}

#mom {
  position:relative;
  transition: all .2s ease-in-out;
}

#mom:hover {
  transform: scale(1.1);
}

#mom a:after {
  color: white;
  content: 'Refactored the civic minded application to use a React/Redux frontend with a node.js backend - makes three external API calls and uses SASS to style. Backend deployed to Heroku. Frontend deployed to github pages.';
  padding: 5px;
  font-size: .7em;
  line-height: 1.3em;
  background-color: rgba(00, 00, 00, .6);
  display: none;
  position: absolute;
  top: 3em;
  width: 98%;
  left: 0;
}

#mom:hover a:after {
  display:block;
}

#waldo {
  position:relative;
  transition: all .2s ease-in-out;
}

#waldo:hover {
  transform: scale(1.1);
}

#waldo a:after {
  color: white;
  content: 'A backend RESTful API serverless system that connects endpoints and routes. Technologies used: node.js, JavaScript, RESTful API, CRUD, Authentication, TDD';
  padding: 5px;
  font-size: .7em;
  line-height: 1.3em;
  background-color: rgba(00, 00, 00, .6);
  display: none;
  position: absolute;
  top: 3em;
  width: 98%;
  left: 0;
}

#waldo:hover a:after {
  display:block;
}

#vote {
  position:relative;
  transition: all .2s ease-in-out;
}

#vote:hover {
  transform: scale(1.1);
}

#vote a:after {
  color: white;
  content: 'A server side rendered site to connect voters with their representatives. Technologies Used: JavaScript, Node.js, APIs, Chart.js, CSS and HTML5';
  padding: 5px;
  font-size: .7em;
  line-height: 1.3em;
  background-color: rgba(00, 00, 00, .6);
  display: none;
  position: absolute;
  top: 3em;
  width: 98%;
  left: 0;
}

#vote:hover a:after {
  display:block;
}

#store {
  position:relative;
  transition: all .2s ease-in-out;
}

#store:hover {
  transform: scale(1.1);
}

#store a:after {
  color: white;
  content: 'An e-commerce template store. Technologies Used: React, Gatsby, JavaScript, GraphQL, SASS';
  padding: 5px;
  font-size: .7em;
  line-height: 1.3em;
  background-color: rgba(00, 00, 00, .6);
  display: none;
  position: absolute;
  top: 3em;
  width: 98%;
  left: 0;
}

#store:hover a:after {
  display:block;
}