@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  background: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 19pt;
  font-weight: 300;
  line-height: 1.75em;
  color: #888; }
  body #header-text {
    color: #222629;
    position: relative;
    text-align: center; }
    body #header-text header {
      background-image: url("https://images.unsplash.com/photo-1529917365646-d9b0d968d713?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2253&q=80");
      padding: 2em; }
      body #header-text header #menu img {
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 10px;
        width: 2em;
        position: absolute;
        top: 1em;
        left: 1em; }
      body #header-text header #menu #side-nav {
        display: none; }
      body #header-text header #menu:hover img {
        opacity: 0; }
      body #header-text header #menu:hover #side-nav {
        background-color: rgba(0, 0, 0, 0.74);
        display: block;
        border-radius: 10px;
        padding-top: 1em;
        position: fixed;
        top: 0;
        left: 0;
        width: 20%;
        height: 100%;
        text-align: left;
        padding-left: 1em;
        z-index: 3; }
        body #header-text header #menu:hover #side-nav a {
          text-decoration: none;
          color: white; }
      body #header-text header h2 {
        display: block;
        font-size: 1.5em;
        -webkit-margin-after: 0.83em;
                margin-block-end: 0.83em;
        -webkit-margin-start: 0px;
                margin-inline-start: 0px;
        -webkit-margin-end: 0px;
                margin-inline-end: 0px;
        font-weight: bold;
        line-height: 1.5em; }
      body #header-text header p {
        display: block;
        -webkit-margin-before: 1em;
                margin-block-start: 1em;
        -webkit-margin-after: 1em;
                margin-block-end: 1em;
        -webkit-margin-start: 0px;
                margin-inline-start: 0px;
        -webkit-margin-end: 0px;
                margin-inline-end: 0px; }

#button {
  position: relative;
  display: inline-block;
  border-radius: 0.35em;
  color: #fff !important;
  text-decoration: none;
  padding: 0.75em 2.5em 0.75em 2.5em;
  background-color: #72aba2;
  border: 0;
  cursor: pointer; }
  #button:hover {
    background-color: #9ececc; }

footer p {
  text-align: center;
  font-size: .7em; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  background: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 19pt;
  font-weight: 300;
  line-height: 1.75em;
  color: #888; }
  body #portfolio {
    background-color: #f5fafa;
    padding: 2em; }
    body #portfolio h2 {
      display: block;
      font-size: 1.5em;
      -webkit-margin-after: 0.83em;
              margin-block-end: 0.83em;
      -webkit-margin-start: 0px;
              margin-inline-start: 0px;
      -webkit-margin-end: 0px;
              margin-inline-end: 0px;
      font-weight: bold;
      line-height: 1.5em;
      text-align: center;
      padding-top: 1em; }
    body #portfolio p {
      display: block;
      -webkit-margin-before: 1em;
              margin-block-start: 1em;
      -webkit-margin-after: 1em;
              margin-block-end: 1em;
      -webkit-margin-start: 0px;
              margin-inline-start: 0px;
      -webkit-margin-end: 0px;
              margin-inline-end: 0px;
      text-align: center; }
    body #portfolio .deck {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-around;
              justify-content: space-around;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      body #portfolio .deck .card img {
        max-width: 500px; }
      body #portfolio .deck .card h3 {
        border-bottom: 2px double lightgray;
        border-top: 2px double lightgray;
        text-align: center;
        margin-bottom: 1em; }

#todo {
  position:relative;
  transition: all .2s ease-in-out;
}

#todo:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

#todo a:after {
  color: white;
  content: 'A portfolio to hold all the little apps that I develop in my free time.';
  padding: 5px;
  font-size: .7em;
  line-height: 1.3em;
  background-color: rgba(00, 00, 00, .6);
  display: none;
  position: absolute;
  top: 3em;
  width: 98%;
  left: 0;
}

#todo:hover a:after {
  display:block;
}

#photography {
  position:relative;
  transition: all .2s ease-in-out;
}

#photography:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

#photography a:after {
  color: white;
  content: 'A photography portfolio. Technologies Used: CMS, Wordpress, Photoshop, CSS and HTML5';
  padding: 5px;
  font-size: .7em;
  line-height: 1.3em;
  background-color: rgba(00, 00, 00, .6);
  display: none;
  position: absolute;
  top: 3em;
  width: 98%;
  left: 0;
}

#photography:hover a:after {
  display:block;
}

#mom {
  position:relative;
  transition: all .2s ease-in-out;
}

#mom:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

#mom a:after {
  color: white;
  content: 'Refactored the civic minded application to use a React/Redux frontend with a node.js backend - makes three external API calls and uses SASS to style. Backend deployed to Heroku. Frontend deployed to github pages.';
  padding: 5px;
  font-size: .7em;
  line-height: 1.3em;
  background-color: rgba(00, 00, 00, .6);
  display: none;
  position: absolute;
  top: 3em;
  width: 98%;
  left: 0;
}

#mom:hover a:after {
  display:block;
}

#waldo {
  position:relative;
  transition: all .2s ease-in-out;
}

#waldo:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

#waldo a:after {
  color: white;
  content: 'A backend RESTful API serverless system that connects endpoints and routes. Technologies used: node.js, JavaScript, RESTful API, CRUD, Authentication, TDD';
  padding: 5px;
  font-size: .7em;
  line-height: 1.3em;
  background-color: rgba(00, 00, 00, .6);
  display: none;
  position: absolute;
  top: 3em;
  width: 98%;
  left: 0;
}

#waldo:hover a:after {
  display:block;
}

#vote {
  position:relative;
  transition: all .2s ease-in-out;
}

#vote:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

#vote a:after {
  color: white;
  content: 'A server side rendered site to connect voters with their representatives. Technologies Used: JavaScript, Node.js, APIs, Chart.js, CSS and HTML5';
  padding: 5px;
  font-size: .7em;
  line-height: 1.3em;
  background-color: rgba(00, 00, 00, .6);
  display: none;
  position: absolute;
  top: 3em;
  width: 98%;
  left: 0;
}

#vote:hover a:after {
  display:block;
}

#store {
  position:relative;
  transition: all .2s ease-in-out;
}

#store:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

#store a:after {
  color: white;
  content: 'An e-commerce template store. Technologies Used: React, Gatsby, JavaScript, GraphQL, SASS';
  padding: 5px;
  font-size: .7em;
  line-height: 1.3em;
  background-color: rgba(00, 00, 00, .6);
  display: none;
  position: absolute;
  top: 3em;
  width: 98%;
  left: 0;
}

#store:hover a:after {
  display:block;
}
#about {
  background-color: #ecf1f1; }
  #about h2 {
    display: block;
    font-size: 1.5em;
    -webkit-margin-after: 0.83em;
            margin-block-end: 0.83em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: bold;
    line-height: 1.5em;
    text-align: center;
    padding-top: 1em; }
  #about p {
    display: block;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    text-align: center;
    padding: 1em; }
  #about img {
    max-width: 100%; }

#contact {
  background-image: url(/static/media/banner.0ff2d973.jpg);
  background-size: cover;
  color: #eceef1;
  margin-top: -30px; }
  #contact #background-black {
    background-color: rgba(0, 0, 0, 0.5); }
  #contact h2 {
    display: block;
    font-size: 1.5em;
    -webkit-margin-after: 0.83em;
            margin-block-end: 0.83em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: bold;
    line-height: 1.5em;
    text-align: center;
    padding-top: 1em; }
  #contact p {
    display: block;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    text-align: center;
    padding: 1em; }
  #contact #button {
    margin-left: 1em;
    margin-bottom: 1em; }

