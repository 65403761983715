#button{
  position: relative;
  display: inline-block;
  border-radius: 0.35em;
  color: #fff !important;
  text-decoration: none;
  padding: 0.75em 2.5em 0.75em 2.5em;
  background-color: #72aba2;
  border: 0;
  cursor: pointer;

  &:hover{
    background-color: #9ececc;
  }
}