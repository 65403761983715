@import url('https://fonts.googleapis.com/css?family=Montserrat');

#contact{
  background-image: url('../../img/banner.jpg');
  background-size: cover; 
  color: #eceef1;
  margin-top: -30px;
  
  #background-black{
    background-color: rgba(0, 0, 0, .5);

  }

  h2{
    display: block;
    font-size: 1.5em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    line-height: 1.5em;
    text-align: center;
    padding-top: 1em;
  }

  p{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: center;
    padding: 1em;
  }

  #button{
    margin-left: 1em;
    margin-bottom: 1em;
  }

}